import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, ListGroup, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { CSVLink, CSVDownload } from "react-csv";
import { DataGrid } from "@mui/x-data-grid";

export default function AdminAllOrganizations() {
  const maxW = isMobile ? "100%" : "80%";
  const [groups, setAllGroups] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [allOrders, setAllOrders] = useState([])
  const [pastWeekOrders, setPastWeekOrders] = useState([])
  const [orderDetails, setOrderDetails] = useState({
    totalSales: 0,
    totalOrders: 0,
    totalCustomers: 0,
    totalEvents: 0,
    totalFreeEvents: 0,
    totalSaleEvent: 0,
    totalOrgs: 0,
  })
  const [totalTickets, setTotalTickets] = useState(0)

  const [csvOrgData,setCSVOrgData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"]
  ]);

  const [csvEventData,setCSVEventData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"]
  ]);

  const eventsCol = [
      { field: "id", headerName: "ID" },
      { field: "title", headerName: "Name",},
    { field: "startDate", headerName: "Start Date",},
    { field: "endDate", headerName: "End Date",},
    { field: "orgId", headerName: "Org ID",},
    { field: "orgName", headerName: "Org Name",},
    { field: "sales", headerName: "Total Sales(CAD)",},
    { field: "orderCount", headerName: "Orders"},
    { field: "userCount", headerName: "Members",},
    { field: "ticketCount", headerName: "Tickets",},
    { field: "viewCount", headerName: "Views",}
]

const groupsCol = [
    { field: "id", flex: 0.2, headerName: "ID" },
    { field: "name", flex: 0.5, headerName: "Name" },
    { field: "event_count", flex: 0.3, headerName: "Events" },
    { field: "created", flex: 0.2, headerName: "Created" },
]


  useEffect(() => {
      fires
      .collection('admin')
      .doc(auth.currentUser.uid)
      .get()
      .then((snapshot)=>{
          if (snapshot.exists){
            loadAllOrders()
          }
          else{
            window.location.href = "/404"
          }
      })
  },[])

  function loadAllEvents(allOrders){
    fires
    .collection('events')
    .where('summary.views','>=',0)
    .orderBy('summary.views', 'desc')
    .get().then((snapshot) => {
        let vEvents = []
        let eventCSVD = [['ID','Name','Start Date','End Date','Org ID','Org Name','Total Sales','Users','Tickets','Orders','Views']]
        snapshot.docs.forEach((doc) => {
            let eveId = doc.id
            let eveData = doc.data()

            var eveTitle = ""
            var eveStartD = ""
            var eveEndD = ""
            var eveOrgId = ""
            var eveOrgName = ""
            var eveUserCount = 0
            var eveViewCount = 0
            var eveTicketCount = 0

            let eveOrderCount = 0

            if (eveData.title != null){
                eveTitle = eveData.title
            }
            if (eveData.startDate != null){
                eveStartD = eveData.startDate.toDate().toISOString()
            }
            if (eveData.endDate != null){
                eveEndD = eveData.endDate.toDate().toISOString()
            }
            if (eveData.orgId != null){
                eveOrgId = eveData.orgId
            }
            if (eveData.user != null){
                if (eveData.user.userOrg != null){
                    eveOrgName = eveData.user.userOrg
                }
            }
            if (eveData.summary != null){
                if (eveData.summary.users != null){
                    eveUserCount = eveData.summary.users
                }
                if (eveData.summary.tickets != null){
                    eveTicketCount = eveData.summary.tickets
                }
                if (eveData.summary.views != null){
                    eveViewCount = eveData.summary.views
                }
            }

            let eventOrders = allOrders.filter((orderItm) => {
              return orderItm.eventId == eveId
            })
            let salesAmount = 0
            eventOrders.forEach((orderItm) => {
              if (orderItm.amount != null){
                salesAmount += orderItm.amount
              }
            })
            eveOrderCount = eventOrders.length

            vEvents.push({
                id: eveId,
                title: eveTitle,
                startDate: eveStartD,
                endDate: eveEndD,
                orgId: eveOrgId,
                orgName: eveOrgName,
                sales: Math.round(salesAmount),
                orderCount: eveOrderCount,
                userCount: eveUserCount,
                ticketCount: eveTicketCount,
                viewCount: eveViewCount,
            })
            eventCSVD.push([
                eveId,
                eveTitle,
                eveStartD,
                eveEndD,
                eveOrgId,
                eveOrgName,
                Math.round(salesAmount),
                eveUserCount,
                eveTicketCount,
                eveOrderCount,
                eveViewCount
            ])

        })
        vEvents = vEvents.sort((a,b) => {
            return b.viewCount - a.viewCount
        })

        setAllEvents(vEvents)
        setCSVEventData(eventCSVD)
        loadAllGroups(vEvents)
    })
    .catch((e) => {
        alert(e.message)
    })

  }



  function loadAllGroups(vEvents){
    fires
    .collection('groups')
    .where('status','==','live')
    .orderBy('timestamp', 'desc')
    .get().then(snapshot => {
        let groupsRe = []
        let orgCSVD = [['ID','Name','Events','Created']]
        snapshot.docs.forEach((doc) => {
            let groupId = doc.id
            let groupData = doc.data()

            let groupEventCount = 0
            // if (groupData.event_count != null){
            //     groupEventCount = groupData.event_count
            // }
            let eventData = vEvents.filter((evt)=>{
              return evt.orgId == groupId
            })

            groupEventCount = eventData.length

            let groupCreated = ""
            if (groupData.timestamp != null){
                groupCreated = groupData.timestamp.toDate().toISOString()
            }
            groupsRe.push({
                id: groupId, 
                name: groupData.name, 
                event_count: groupEventCount, 
                created: groupCreated})
            orgCSVD.push([
                groupId,
                groupData.name,
                groupEventCount,
                groupCreated])
        })
        setAllGroups(groupsRe)
        setCSVOrgData(orgCSVD)
        
    })
  }

  async function loadAllTickets(){
    const collectionRef = fires.collection('event_tickets');
    //const snapshot = await collectionRef.count().get();
    
    fires
    .collection('event_tickets')
    .where('timestamp','>=',firebase.firestore.Timestamp.fromDate(new Date('2024-12-01')))
    .where('timestamp','<=',firebase.firestore.Timestamp.fromDate(new Date('2024-12-31')))
    .get()
    .then((snapshot) => {
      let freeCount = 0
      let paidCount = 0
      snapshot.docs.forEach((doc) => {
        let ticketData = doc.data()
        if (ticketData.paymentId != null){
          if (ticketData.paymentId != ""){
            paidCount += 1
          }
          else{
            freeCount += 1
          }
        }
      })
      setTotalTickets(snapshot.docs.length)
      alert(freeCount + " " + paidCount)
    })
  }

  function loadAllOrders(){
    loadAllTickets()
    fires
    .collection('orders')
    .where('timestamp','>=',firebase.firestore.Timestamp.fromDate(new Date('2024-12-01')))
    .where('timestamp','<=',firebase.firestore.Timestamp.fromDate(new Date('2024-12-31')))
    .orderBy('timestamp', 'desc')
    .get().then((snapshot) => {
      let totalSales = 0
      let freeEventOrder = 0
      let saleEventOrder = 0
      let totalOrders = snapshot.docs.length
      let userList = []
      let eventList = []
      let orgList = []

      let allOrders = []
      snapshot.docs.forEach((doc) => {
        let orderItm = doc.data()
        let orderId = doc.id
        allOrders.push(orderItm)

        let orderAmount = 0
        let userEmail = ""
        let eventId = ""
        let orgId = ""
        if (orderItm.amount != null){
          orderAmount = orderItm.amount
        }
        if (orderItm.userEmail != null){
          userEmail = orderItm.userEmail
        }
        if (orderItm.eventId != null){
          eventId = orderItm.eventId
        }
        if (orderItm.orgId != null){
          orgId = orderItm.orgId
        }
        if (userEmail != "" && !userList.includes(userEmail)){
          userList.push(userEmail)
        }
        if (eventId != "" && !eventList.includes(eventId)){
          eventList.push(eventId)
        }
        if (orgId != "" && !orgList.includes(orgId)){
          orgList.push(orgId)
        }
        totalSales += orderAmount
        if (orderAmount == 0){
          freeEventOrder += 1
        }
        else{
          saleEventOrder += 1
        }
        
        
      })
      setOrderDetails({
        totalSales: totalSales,
        totalOrders: totalOrders,
        totalCustomers: userList.length,
        totalEvents: eventList.length,
        totalFreeEvents: freeEventOrder,
        totalSaleEvent: saleEventOrder,
        totalOrgs: orgList.length,
      })
      loadAllEvents(allOrders)
    })
  }

  async function generateEventCount(groupId){
      if (groupId != null && groupId != ""){
          const eventSnap = await fires.collection('events').where('orgId', '==', groupId).where('status','==','live').get()
          const eventCount = eventSnap.docs.length
          if (eventCount > 0) {
            fires.collection('groups').doc(groupId).update({event_count: eventCount}).then((d)=>{
                
            })
          }
      }
  }


  return (
    <>
      <Helmet>
        <title>Admin Organization</title>
        <meta property="og:title" content="View Admins" />
        <meta
          property="og:description"
          content="View Admins"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="View Admins"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body>

            <div style={{ marginTop: "25px", marginBottom: "45px" }}>
              <Card.Title 
                    className="text-center" 
                    style={{fontSize: '20px', fontWeight: 'bold'}}>
                  总订单数据 (自从2024-09-11)
                </Card.Title>
              <Row md={4} style={{marginTop: '25px'}}>
              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总销售额</div>
                      <div className="fw-bold mt-2">{orderDetails.totalSales.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总订单数</div>
                      <div className="fw-bold mt-2">{orderDetails.totalOrders}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总订单用户数</div>
                      <div className="fw-bold mt-2">{orderDetails.totalCustomers}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总订单活动数</div>
                      <div className="fw-bold mt-2">{orderDetails.totalEvents}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">免费活动订单数</div>
                      <div className="fw-bold mt-2">{orderDetails.totalFreeEvents}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">收费活动订单数</div>
                      <div className="fw-bold mt-2">{orderDetails.totalSaleEvent}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总订单活动方</div>
                      <div className="fw-bold mt-2">{orderDetails.totalOrgs}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col style={{marginTop: '10px'}}>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总订单票数</div>
                      <div className="fw-bold mt-2">{totalTickets}</div>
                  </Card.Body>
              </Card>
              </Col>
              </Row>
              </div>
            
            <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '20px', fontWeight: 'bold'}}>
                  {groups.length}个活动方
                </Card.Title>
                <div className="d-flex align-items-center justify-content-center">
                <CSVLink 
            filename={"total-orgs-eventgo" + ".csv"}
            data={csvOrgData}
            className="btn btn-dark"
            style={{color: 'white'}}>下载活动方数据</CSVLink>
            </div>

              </div>
              <div style={{marginTop: 25, marginBottom: 25}}>
              <DataGrid 
        checkboxSelection 
        editMode="row"
        sx={{ overflowX: 'scroll', marginTop: '15px' }}
        rows={groups} 
        columns={groupsCol} />
              </div>

                <div>
                <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '20px', fontWeight: 'bold'}}>
                  {allEvents.length}个活动
                </Card.Title>
                <div className="d-flex align-items-center justify-content-center">
                <CSVLink 
            filename={"total-events-eventgo" + ".csv"}
            data={csvEventData}
            className="btn btn-dark"
            style={{color: 'white'}}>下载活动数据</CSVLink>
                </div>
              </div>
                <DataGrid 
        checkboxSelection 
        editMode="row"
        sx={{ overflowX: 'scroll', marginTop: '15px' }}
        rows={allEvents} 
        columns={eventsCol} />
                </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
