import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Container,
  Accordion,
  ListGroup,
  Badge,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { Scanner } from "@yudiel/react-qr-scanner";
import { DataGrid } from "@mui/x-data-grid";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "firebase/firestore";
import SuperAdminAlert from "../SuperAdminAlert";
import { createTicketId, getNow } from "../Helper";
import QRScannerModal from "./QRScannerModal";
import ButtonCheckinOne from "./ButtonCheckinOne";
import ButtonCancelCheckinAll from "./ButtonCancelCheckinAll";

export default function CheckInSystem() {
  const { currentUser, logout } = useAuth();
  const [posts, setPosts] = useState([]);
  const [eventData, setEventData] = useState({});
  const [eventDisplayMembers, setEventDisplayMembers] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [showInvitees, setShowInvitees] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [showQRScanner, setShowQRScanner] = useState(false);

  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [checkedInTickets, setCheckedInTickets] = useState(0);

  
  const [displayMode, setDisplayMode] = useState(0);
  const [superAdminInEffect, setSuperAdminInEffect] = useState(false);
  var uid = currentUser.uid;
  const [inviteeDisplayType, setInviteeDisplayType] = useState(0);
  const [orgData, setOrgData] = useState({});
  const [checkinTicketDetails, setCheckinTicketDetails] = useState({})
  const [checkinTickets, setCheckinTickets] = useState([]);
  const [uncheckinTickets, setUnCheckinTickets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  

  const [eventCount, setEventCount] = useState(0)
  const [orgTicketIds, setOrgTicketIds] = useState([])
  const [orgTicketDatas, setOrgTicketDatas] = useState([])
  const [ticketCount, setTicketCount] = useState(0)

  const routeToLogout = () => {
    logout();
    window.location.href = "/";
  };

  const routeToBack = () => {
    window.location.href = "/";
  };

  const [checkInData, setCheckInData] = useState({
      success: false,
      type: '',
      ticketId: '',
      orderId: '',
      orderData: {
          title: '',
          eventId: '',
          status: '',
          userEmail: '',
          userName: '',
          tickets: [],
          ticketIds: [],
      },
      ticketData: {}
  })

  const [lastUpdate, setLastUpdate] = useState(new Date())

  
  const showInfoWithTicketId = (ticketId) => {
    //
    fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPILoadCheckInData?ticket_id=${ticketId}&user_uid=${auth.currentUser.uid}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response)=>{
      const responseTask = response.json()
      responseTask.then((data)=>{
        if (data.success){
          setCheckInData({...data})
        }
        setLastUpdate(new Date())
      })
    })
  }

  const checkInWithTicketId = (ticketId, eventId) => {
    //
    fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPICheckInWithTicketId?ticket_id=${ticketId}&user_uid=${auth.currentUser.uid}&event_id=${eventId}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response)=>{
      const responseTask = response.json()
      responseTask.then((data)=>{
        if (data.success){
          setCheckInData({...data})
        }
        setLastUpdate(new Date())
      })
    })
  }
 

  useEffect(() => {
    fires
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
            const userData = snapshot.data();
            const uorgId = userData.orgId;
            if (uorgId != null && uorgId != ""){

            }
        }
      })
    
  }, [searchQuery]);
  


  return (
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh", minWidth: "100vw" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: "100%", marginTop: "25px", marginBottom: "25px" }}
        >
          <Card className="border-0">
            <Card.Body className="align-items-center justify-content-center">
              <div className="d-flex justify-content-between align-items-start">
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: isMobile ? "25px" : "45px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Checkin
                </Card.Title>

                <div style={{ display: "flex" }}>
                  
                  <Button
                    variant="dark"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                      setShowQRScanner(true);
                    }}
                  >
                    Scan
                  </Button>

                  <DropdownButton
                    style={{ marginRight: "10px" }}
                    title="Sort"
                    id=""
                    variant="outline-dark"
                    onSelect={(e) => {
                      
                    }}
                  >
                    <Dropdown.Item eventKey="filter-by-name">
                      Sort by name
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="filter-by-timestamp">
                      Sort by time
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>

              <Form.Control
                type="text"
                placeholder="Search Order/Ticket"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  width: isMobile ? "100%" : "25%",
                  marginTop: "15px",
                  marginLeft: "auto",
                  marginRight: "0",
                }}
              />
              <Button 
                variant="dark" 
                style={{
                    width: isMobile ? "100%" : "25%",
                    marginLeft: "auto",
                    marginRight: "0",
                    marginTop: '10px'}} onClick={()=>{
                  //search
                  showInfoWithTicketId(searchQuery.replace(" ","-"))
              }}>Search</Button>

        <div style={{marginTop: '25px', marginBottom: '25px'}}>
        <Scanner
          onResult={(text, _) => {
            if (text.includes("/ticket-id/")) {
              const itemId = text.split("/ticket-id/")[1];
              //check if ticket id and order id
              showInfoWithTicketId(itemId)
              return;
            }
          }}
          onError={(error) => {
            alert("Error: " + error.message)
          }}
        />
        </div>

            <div style={{ marginTop: "25px" }}>
                <div className="fw-normal">
                  Recog ID: <b>{checkInData.ticketId}</b>
                </div>
                <div className="fw-normal">
                  Type: <b>{checkInData.type}</b>
                </div>
                <div className="fw-normal mt-2">
                  Order ID: <b>{checkInData.orderId}</b>
                </div>
                <div className="fw-normal">
                  Order Event: <b>{checkInData.orderData.title}</b>
                </div>
                <div className="fw-normal">
                  Order Email: <b>{checkInData.orderData.userEmail}</b>
                </div>
                <div className="fw-normal">
                  Order Customer: <b>{checkInData.orderData.userName}</b>
                </div>
                <div className="fw-normal">
                  Order Status: <b>{checkInData.orderData.status}</b>
                </div>
                <div className="fw-normal">
                  Tickets Count: <b>{checkInData.orderData.ticketIds.length}</b>
                </div>
                
              </div>

              <div className="fw-normal">
                  Last Updated: <b>{lastUpdate.toLocaleString('en')}</b>
            </div>

              
              <div>
                  <Card.Title>Tickets ({checkInData.orderData.tickets.length})</Card.Title>
              </div>
              {checkInData.orderData.tickets && 
              <ListGroup style={{marginTop: '25px'}}>
                {
                    checkInData.orderData.tickets.map((ticketItm)=>{
                        let ticketId = ticketItm.ticketId
                        let ticketName = ""
                        let ticketType = ""
                        let ticketStatus = ""
                        let buyerName = ""
                        let ownerName = ""
                        let checkIn = false
                        if (ticketItm.checkin != null){
                            checkIn = ticketItm.checkin
                        }
                        if (ticketItm.ticketName != null){
                            ticketName = ticketItm.ticketName
                        }
                        if (ticketItm.ticketType != null){
                            ticketType = ticketItm.ticketType
                        }
                        if (ticketItm.ownerName != null){
                            ownerName = ticketItm.ownerName
                        }
                        if (ticketItm.userName != null){
                            buyerName = ticketItm.userName
                        }
                        if (ticketItm.status != null){
                            ticketStatus = ticketItm.status
                        }
                        return <ListGroup.Item>
                            <div className="fw-normal">
                                Ticket ID: <Card.Link href={"https://evtgo.com/ticket-id/"+ticketId}>{ticketId}</Card.Link>
                            </div>
                            <div className="fw-normal">
                                Ticket Name: <b>{ticketName}</b>
                            </div>
                            <div className="fw-normal">
                                Ticket Holder Name: <b>{buyerName}</b>
                            </div>
                            <div className="fw-normal">
                                Buyer Name: <b>{ownerName == "" ? buyerName : ownerName}</b>
                            </div>
                            <div className="fw-normal">
                                Status: <b>{ticketStatus}</b>
                            </div>
                            
                            
                            <div style={{marginTop: '15px'}}>
                                <div className="fw-normal">
                                    Check-In: <b>{checkIn ? 'YES' : 'NO'}</b>
                                </div>
                                <Button variant="dark" hidden={ticketStatus == 'cancel' || checkIn} onClick={()=>{
                                    //checkin ticket
                                    if (!checkIn && checkInData.orderData.eventId != null && checkInData.orderData.eventId != ""){
                                        //
                                        let evtId = checkInData.orderData.eventId
                                        checkInWithTicketId(ticketId,evtId)
                                    }
                                    else{
                                        if (checkIn){
                                            alert("Already Check-In")
                                        }
                                        if (checkInData.orderData.eventId == null || checkInData.orderData.eventId == ""){
                                            alert("Event ID not found")
                                        }
                                    }
                                }}>CHECK-IN</Button>
                            </div>
                            </ListGroup.Item>
                    })
                }
              </ListGroup>}

              {checkInData.orderData.eventId && <div style={{marginTop: '25px'}}>
                  <div>Go to Check-In Dashboard</div><br/>
                  <div>{checkInData.orderData.title}</div><br/>
                  <Card.Link href={"/event-checkin/" + checkInData.orderData.eventId}>Dashboard</Card.Link>
              </div>} 
              
            </Card.Body>
          </Card>
        </div>
      </Container>
  );
}
