import React, { useState, useEffect } from "react";
import { Modal, Card, Button, Accordion, ListGroup } from "react-bootstrap";
import { Scanner } from "@yudiel/react-qr-scanner";
import { getNow } from "../Helper";
import { fires, auth } from "../../firebase";

export default function QRScannerModal({
  id,
  showQRScanner,
  setShowQRScanner,
  eventMembers,
  checkinTickets,
  uncheckinTickets,
  checkinTicketDetails,
  eventData,
}) {
  const [checkinWithScannerMessage, setCheckinWithScannerMessage] =
    useState("");
  const [qrResults, setQRResults] = useState("");
  const [currentCheckInMember, setCurrentCheckInMember] = useState({
    name: "",
    email: "",
    tickets: [],
  });
  const [currentCheckInTicket, setCurrentCheckInTicket] = useState({
    userName: "",
    ticketType: "",
    ticketTypeId: "",
    eventTitle: "",
  });
  const [scannedTicketId, setScannedTicketId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (!currentCheckInMember.email) {
      return;
    }
    const memberDocRef = fires
      .collection("events")
      .doc(id)
      .collection("members")
      .doc(currentCheckInMember.email);
    const unsubscribe = memberDocRef.onSnapshot((doc) => {
      if (doc.exists) {
        const memberData = doc.data();
        setCurrentCheckInMember((prevState) => ({
          ...prevState,
          checkinCount: memberData.checkinCount || 0,
        }));
      }
    });
    return () => unsubscribe();
  }, [currentCheckInMember.email, id]);

  const checkInWithOrderId = (orderId) => {
    //
    fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPICheckInWithOrderID?order_id=${orderId}&event_id=${id}&user_uid=${auth.currentUser.uid}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response)=>{
      const responseTask = response.json()
      responseTask.then((data)=>{
        if (data.success){
          let ticketItemss = []
          if (data.tickets != null){
            ticketItemss = data.tickets
            if (ticketItemss.length > 0) {
              let ticketId = ticketItemss[0]
              checkinWithTicketId(ticketId)
            }
            
          }
          if (data.email != null){
            
          }
        }
      })
    })
  }

  const checkinWithTicketId = async (tickId) => {
    if (tickId === "") {
      setQRResults("");
      setCheckinWithScannerMessage("No Ticket Found");
      return;
    }

    try {
      const ticketSnap = await fires
        .collection("events")
        .doc(id)
        .collection("tickets")
        .doc(tickId)
        .get();

      if (ticketSnap.exists) {
        const ticketData = ticketSnap.data();
        const membersData = eventMembers.filter((memItm) => {
          const tickets = memItm.tickets ?? [];
          return tickets.includes(tickId);
        });
        if (ticketData.checkin) {
          setQRResults("");
          if (membersData.length > 0) {
            const fMember = membersData[0];
            setCurrentCheckInMember(fMember);
          }
          else{
            setCurrentCheckInMember({ name: "", email: "", tickets: [] });
          }
          setCurrentCheckInTicket({...ticketData})
          setCheckinWithScannerMessage("This ticket already checked in");
          setScannedTicketId(tickId);
          return;
        }
        if (membersData.length > 0) {
          const fMember = membersData[0];
          setCurrentCheckInMember(fMember);
          setCurrentCheckInTicket({...ticketData})
          setScannedTicketId(tickId);
          setQRResults(tickId);
          setIsButtonDisabled(false);
          setCheckinWithScannerMessage(`${tickId} Scanned`);
        } else {
          setQRResults("");
          setCurrentCheckInMember({ name: "", email: "", tickets: [] });
          setCheckinWithScannerMessage("No member found");
          setCurrentCheckInTicket({userName: "",ticketType: "",ticketTypeId: "", eventTitle: ""})
        }
      } else {
        setQRResults("");
        setCurrentCheckInMember({ name: "", email: "", tickets: [] });
        setCheckinWithScannerMessage("No Ticket Found");
      }
    } catch (error) {
      console.error("Error checking ticket: ", error);
      setCheckinWithScannerMessage(`Error: ${error.message}`);
    }
  };

  const handleCheckInAll = async () => {
    if (!scannedTicketId || !currentCheckInMember.email) return;
    setIsButtonDisabled(true);
    try {
      await fires
        .collection("events")
        .doc(id)
        .collection("members")
        .doc(currentCheckInMember.email)
        .update({
          checkinBy: "admin",
          checkinMethod: "QR",
          checkinStatus: true,
          checkinCount: (currentCheckInMember.checkinCount || 0) + 1,
          checkinTimestamp: getNow(),
        });

        currentCheckInMember
        .tickets
        .forEach((ticketId) => {
          fires
          .collection("events")
          .doc(id)
          .collection("tickets")
          .doc(ticketId)
          .update({
            checkin: true,
            checkinBy: "admin",
            checkinMethod: "QR",
            checkinTimestamp: getNow(),
          });
        })

      setCheckinWithScannerMessage(`${scannedTicketId} Checked in successfully`);
      setQRResults(scannedTicketId);

    }catch (error) {
      console.error("Error during check-in: ", error);
      setCheckinWithScannerMessage(`Checkin Error: ${error.message}`);
    }
  }

  const handleCheckIn = async () => {
    if (!scannedTicketId || !currentCheckInMember.email) return;
    setIsButtonDisabled(true);
    try {
      
      await fires
        .collection("events")
        .doc(id)
        .collection("tickets")
        .doc(scannedTicketId)
        .update({
          checkin: true,
          checkinBy: "admin",
          checkinMethod: "QR",
          checkinTimestamp: getNow(),
        });
      
      await fires
        .collection("events")
        .doc(id)
        .collection("members")
        .doc(currentCheckInMember.email)
        .update({
          checkinBy: "admin",
          checkinMethod: "QR",
          checkinStatus: true,
          checkinCount: (currentCheckInMember.checkinCount || 0) + 1,
          checkinTimestamp: getNow(),
        });

      setCheckinWithScannerMessage(`${scannedTicketId} Checked in successfully`);
      setQRResults(scannedTicketId);
    } catch (error) {
      console.error("Error during check-in: ", error);
      setCheckinWithScannerMessage(`Checkin Error: ${error.message}`);
    }
  };

  return (
    <Modal
      show={showQRScanner}
      onHide={() => {
        setShowQRScanner(false);
      }}
    >
      <Modal.Header closeButton>
        <Card.Title>QR Checkin</Card.Title>
      </Modal.Header>
      <Modal.Body>
        <Scanner
          onResult={(text, _) => {
            if (text.includes("/ticket-id/")) {
              const itemId = text.split("/ticket-id/")[1];
              let ticketId = ""
              let orderId = ""
              //check if ticket id and order id
              if (itemId.includes('-')){
                let itemH = itemId.split('-').join('')
                if (Number(itemH) > 0){
                  ticketId = itemId
                }
                else{
                  orderId = itemId
                }
              }
              if (ticketId != ""){
                checkinWithTicketId(ticketId);
              }
              if (orderId != ""){
                checkInWithOrderId(orderId)
              }
              
              return;
            }
            setCheckinWithScannerMessage("No Ticket");
          }}
          onError={(error) => {
            setCheckinWithScannerMessage(`Checkin Error: ${error?.message}`);
          }}
        />
        <Button
          variant="dark"
          disabled={!scannedTicketId || isButtonDisabled}
          style={{ marginTop: "15px" }}
          onClick={handleCheckIn}
        >
          {"Check-In One"}
        </Button>
        <Button
          variant="dark"
          disabled={!scannedTicketId || isButtonDisabled}
          style={{ marginTop: "15px" }}
          hidden={currentCheckInMember.tickets.length <= 1}
          onClick={handleCheckInAll}
        >
          {"Check-In All"}
        </Button>
        <Card.Text style={{ marginTop: "15px" }}>
          CheckIn Info: {checkinWithScannerMessage}
        </Card.Text>

        <Card.Text style={{ marginTop: "15px" }}>
          Ticket Found: {qrResults}
        </Card.Text>
        <Card.Text style={{ marginTop: "15px" }}>
          Ticket Name: {currentCheckInTicket.eventTitle}
        </Card.Text>
        <Card.Text style={{ marginTop: "15px" }}>
          People Found: {currentCheckInMember.name} (
          {currentCheckInMember.email})
        </Card.Text>
        <Card.Text style={{ marginTop: "5px" }}>
          All Tickets: {currentCheckInMember.tickets.length}
        </Card.Text>
        <ListGroup>
        {currentCheckInMember.tickets.map((ticketItm) => (
          
          <ListGroup.Item key={ticketItm}>
            <Card.Link
              href={`https://tickets.evtgo.com/ticket-id/${ticketItm}`}
            >
              {ticketItm}
            </Card.Link>
            <br />
            <Card.Link onClick={()=>{
              checkinWithTicketId(ticketItm)
            }}>Check-In</Card.Link>
          </ListGroup.Item>
        ))}
        </ListGroup>
        
        <Accordion defaultActiveKey="unchecked" style={{ marginTop: "25px" }}>
          <Accordion.Item eventKey="all">
            <Accordion.Header>
              All Tickets ({Object.keys(checkinTicketDetails).length})
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup className="border-0">
                {Object.keys(checkinTicketDetails).map((tickId) => {
                  let checkinTData = checkinTicketDetails[tickId]
                  let paymentId = ""
                  let ticketType = ""
                  let ticketName = ""
                  let ticketPersonName = ""
                  let checkinStatus = false
                  let ticketTimestamp = getNow()
                  if (checkinTData.paymentId != null){
                    paymentId = checkinTData.paymentId
                  }
                  if (checkinTData.ticketType != null){
                    ticketType = checkinTData.ticketType
                  }
                  if (checkinTData.userName != null){
                    ticketPersonName = checkinTData.userName
                  }
                  if (checkinTData.timestamp != null){
                    ticketTimestamp = checkinTData.timestamp
                  }
                  if (checkinTData.checkin != null){
                    checkinStatus = checkinTData.checkin
                  }

                  if (eventData.prices != null && ticketType != ""){
                    if (eventData.prices[ticketType] != null){
                      const eventPriceItem = eventData.prices[ticketType]
                      if (eventPriceItem.priceDescription != null){
                        ticketName = eventPriceItem.priceDescription
                      }
                    }
                  }

                  return <ListGroup.Item key={tickId}>
                  <Card.Link>{tickId}</Card.Link>
                  <Card.Text style={{color: 'blue', margin: 0}}><b>{ticketName == "" ? "Default Price" : ticketName}</b></Card.Text>
                  <div><b>{ticketPersonName}</b></div>
                  <Card.Text style={{color: checkinStatus ? 'black' : 'gray'}}>{checkinStatus ? 'Checked-In' : 'Not Checked'}</Card.Text>
                  <div>{ticketTimestamp.toDate().toLocaleDateString('en')}</div>
                </ListGroup.Item>
                }
                )}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="unchecked">
            <Accordion.Header>Checkin Next({uncheckinTickets.length})</Accordion.Header>
            <Accordion.Body>
              <ListGroup className="border-0">
                {uncheckinTickets.map((tickId) => {
                  let checkinTData = checkinTicketDetails[tickId]
                  let paymentId = ""
                  let ticketType = ""
                  let ticketName = ""
                  let ticketPersonName = ""
                  let ticketTimestamp = getNow()
                  if (checkinTData.paymentId != null){
                    paymentId = checkinTData.paymentId
                  }
                  if (checkinTData.ticketType != null){
                    ticketType = checkinTData.ticketType
                  }
                  if (checkinTData.userName != null){
                    ticketPersonName = checkinTData.userName
                  }
                  if (checkinTData.timestamp != null){
                    ticketTimestamp = checkinTData.timestamp
                  }

                  if (eventData.prices != null && ticketType != ""){
                    if (eventData.prices[ticketType] != null){
                      const eventPriceItem = eventData.prices[ticketType]
                      if (eventPriceItem.priceDescription != null){
                        ticketName = eventPriceItem.priceDescription
                      }
                    }
                  }

                  return <ListGroup.Item className="" key={tickId}>
                    <Card.Link
                      href={`https://tickets.evtgo.com/ticket-id/${tickId}`}
                    >
                      {tickId}
                    </Card.Link>
                    <Card.Text style={{color: 'blue', margin: 0}}><b>{ticketName == "" ? "Default Price" : ticketName}</b></Card.Text>
                  <div><b>{ticketPersonName}</b></div>
                  <div>{ticketTimestamp.toDate().toLocaleDateString('en')} {ticketTimestamp.toDate().toLocaleTimeString('en')}</div>
                  <Card.Link onClick={()=>{
                    checkinWithTicketId(tickId)
                  }}>Check-In</Card.Link>
                  </ListGroup.Item>
                })}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
    </Modal>
  );
}
